.App {
  min-height: 100vh;
  width: 100%;
}

a {
  text-decoration: none;
}

.highcharts-container,
.highcharts-root {
  width: 100% !important;
}